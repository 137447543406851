import type { SearchResult } from "./types";

const BASE_URL =
  typeof window !== "undefined"
    ? `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`
    : "/";

export const resolver = {
  search: async (query: string) => {
    const url = new URL(`/api/search`, BASE_URL);

    url.searchParams.set("q", query);

    return fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      cache: "no-store",
    })
      .then((data) => data.json() as Promise<SearchResult>)
      .catch(
        (e) =>
          ({
            products: [],
            articles: [],
            categories: [],
          }) as SearchResult
      );
  },
};
