"use client";
import React, {
  type InputHTMLAttributes,
  forwardRef,
  useRef,
  useState,
} from "react";
import { resolver as searchResolver } from "api/search";
import {
  useDebounceCallback,
  useEventListener,
  useLocalStorage,
  useOnClickOutside,
} from "usehooks-ts";
import type { SearchResult } from "api/search/types";
import { Input } from "../atoms/input";
import { Button } from "../atoms/button";
import { cn, sanitizeCategorySlug } from "../../utils";
import { IconSearch } from "../atoms/icons/kieskeurig";

export interface SearchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
}

export interface SearchHistoryEntry {
  title: string;
  link: string;
  timestamp: string;
}

const SearchInputItem = ({
  selected,
  link,
  title,
  type,
  searchValue,
}: {
  selected: boolean;
  link: string;
  title: string;
  type: string;
  searchValue: string;
}) => {
  const highlightValue = (string: string) => {
    const pattern = new RegExp(searchValue, "gi");
    return string.replace(pattern, `<b>${searchValue}</b>`);
  };

  return (
    <li
      className={cn("border-t border-dashed hover:bg-muted", {
        "bg-muted": selected,
      })}
    >
      <a
        href={link}
        className="hover:no-underline w-full p-2.5 text-card-foreground flex justify-between"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: highlightValue(title),
          }}
        />

        <span className="text-muted-foreground italic text-sm">{type}</span>
      </a>
    </li>
  );
};

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ className, type, placeholder, ...props }, ref) => {
    const searchRef = useRef<HTMLDivElement>(null);
    const [results, setResults] = useState<
      (SearchResult & { searchHistory: SearchHistoryEntry[] }) | null
    >(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [searchHistory, setSearchHistoryValue] = useLocalStorage<
      SearchHistoryEntry[]
    >("search-history", []);

    const [searchValue, setSearchValue] = useState("");

    const handleSearchChange = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      setSearchValue(e.target.value);

      if (e.target.value.length < 2) return;
      const data = await searchResolver.search(e.target.value);

      setSelectedIndex(-1);
      setResults({
        searchHistory: searchHistory
          .filter((item) =>
            item.title.toLowerCase().includes(searchValue.toLowerCase())
          )
          .splice(0, 2),
        ...data,
      });
    };
    const debounced = useDebounceCallback(handleSearchChange, 200);

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (searchValue.length <= 2) return;

      const searchLink = `/search?q=${searchValue}`;

      const newSearchHistory = searchHistory.find(
        (item) => item.title.toLowerCase() === searchValue.toLowerCase()
      )
        ? [...searchHistory]
        : [
            ...searchHistory,
            {
              link: searchLink,
              timestamp: new Date().toISOString(),
              title: searchValue,
            },
          ];

      setSearchHistoryValue(newSearchHistory);
      window.location.href = searchLink;
    };

    const onKeyPress = (e: KeyboardEvent) => {
      if (!results) return;
      const totalLength =
        results.searchHistory.length +
        results.products.length +
        results.articles.length +
        results.categories.length;

      if (e.key === "ArrowDown")
        return setSelectedIndex((prev) =>
          prev + 1 === totalLength ? 0 : prev + 1
        );

      if (e.key === "ArrowUp")
        return setSelectedIndex((prev) =>
          prev === 0 ? totalLength - 1 : prev - 1
        );

      if (e.key === "Enter") {
        e.preventDefault();

        if (selectedIndex === -1) {
          const category = results.categories.find(
            (cat) => cat.name.toLowerCase() === searchValue.toLowerCase()
          );

          if (category) {
            return (window.location.href = `/${sanitizeCategorySlug(category.slug)}`);
          } else {
            return (window.location.href = `/search/?q=${searchValue}`);
          }
        }

        const allResults = [
          ...results.searchHistory,
          ...results.products,
          ...results.categories,
          ...results.articles,
        ];

        const currentResult = allResults[selectedIndex];

        if (!currentResult)
          return (window.location.href = `/search/?q=${searchValue}`);

        if ("link" in currentResult)
          return (window.location.href = currentResult.link);

        if ("depth" in currentResult && "slug" in currentResult)
          return (window.location.href = `/${sanitizeCategorySlug(currentResult.slug)}`);

        if ("kieskeurigId" in currentResult)
          return (window.location.href = `/${sanitizeCategorySlug(currentResult.categorySlug)}/product/${currentResult.slug}/`);

        if ("slug" in currentResult)
          return (window.location.href = `/${currentResult.slug}`);
      }
    };

    useEventListener("keydown", onKeyPress);

    const handleClickOutside = () => setResults(null);
    useOnClickOutside(searchRef, handleClickOutside);

    return (
      <div
        id="search-input"
        className={cn(
          "relative w-full max-w-[560px] bg-destructive p-2.5 lg:p-0 lg:bg-transparent",
          className
        )}
        data-testid="search-input__root"
      >
        <form onSubmit={handleSearch}>
          <Input
            className="border-0 bg-card text-card-foreground w-full"
            onChange={debounced}
            placeholder="Zoek een product om te vergelijken..."
          />
          <Button
            aria-label="submit search"
            type="submit"
            className="h-11 absolute top-2.5 right-2.5 lg:px-5 lg:bg-primary lg:text-primary-foreground rounded-md rounded-l-none lg:top-0 lg:right-0 bg-transparent text-primary px-2.5"
          >
            <IconSearch size="20" />
          </Button>
        </form>

        {results !== null && (
          <div
            ref={searchRef}
            className="absolute left-0 top-full bg-card z-50 w-full text-card-foreground"
          >
            <ul>
              {results.searchHistory.map((item, index) => (
                <SearchInputItem
                  key={item.timestamp}
                  title={item.title}
                  link={item.link}
                  selected={index === selectedIndex}
                  searchValue={searchValue}
                  type="Eerder gezocht"
                />
              ))}

              {results.products.map((product, index) => (
                <SearchInputItem
                  key={product.kieskeurigId}
                  title={product.title}
                  link={`/${sanitizeCategorySlug(product.categorySlug)}/product/${product.slug}`}
                  selected={
                    index + results.searchHistory.length === selectedIndex
                  }
                  searchValue={searchValue}
                  type="Product"
                />
              ))}

              {results.categories.map((category, index) => (
                <SearchInputItem
                  key={category.slug}
                  title={category.name}
                  link={`/${category.slug}`}
                  selected={
                    index +
                      results.searchHistory.length +
                      results.products.length ===
                    selectedIndex
                  }
                  searchValue={searchValue}
                  type="Categorie"
                />
              ))}

              {results.articles.map((article, index) => (
                <SearchInputItem
                  key={article.id}
                  title={article.title}
                  link={`/${article.slug}`}
                  selected={
                    index +
                      results.searchHistory.length +
                      results.categories.length +
                      results.products.length ===
                    selectedIndex
                  }
                  searchValue={searchValue}
                  type="Artikel"
                />
              ))}
            </ul>
            <div className="p-5 text-xs text-center border-t">
              Gebruik de pijltjes toetsen om van boven naar beneden te navigeren
              tussen de aanbevolen zoekwoorden.
            </div>
          </div>
        )}
      </div>
    );
  }
);

SearchInput.displayName = "SearchInput";
export { SearchInput };
